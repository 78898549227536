import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
const light = extendTheme({
  colors: {
    brand: {
      100: '#f7fafc',
      // ...
      900: '#1a202c'
    },
    colors: {
      textPrimary: '#F5D47E',
      textGradient100: 'linear-gradient(0deg, #FFF3CE -0.05%, #FFFFFF 100.05%)',
      textGradient200: 'linear-gradient(180deg, #FFE867 0%, rgba(254, 241, 167, 0.8) 100%)'
    }
  }
});
const dark = extendTheme({
  colors: {
    brand: {
      100: 'pink',
      // ...
      900: 'orange'
    }
  }
});

function App() {
  const themes: any = {
    light,
    dark
  };
  const [theme, setTheme] = useState('light');
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      setTheme(theme);
    }
  }, []);
  return (
    <BrowserRouter>
      <ChakraProvider theme={themes[theme]}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
