import { Tag } from '@chakra-ui/react';
import Button from 'components/UI/Button';
import { ButtonType } from 'interfaces';

const Login = () => {
  return (
    <div className="App">
      <Button>Normal</Button>
      <Button type={ButtonType.PRIMARY}>{ButtonType.PRIMARY}</Button>
      <Tag variant="solid" bg="brand.900">
        Sample Tag{' '}
      </Tag>
    </div>
  );
};

export default Login;
