import { useToken } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';
import { ButtonType } from '../../../interfaces';
interface ButtonProps {
  primary?: boolean;
  color?: string;
}

const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.primary ? 'palevioletred' : 'white')};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  color: ${(props) => props.color || 'palevioletred'};
  border: 2px solid palevioletred;
  border-radius: 3px;
  cursor: pointer;
  font-family: 'Passion One';
  font-weight: 700;
`;

const StyledButton = ({ children, type }: { type?: ButtonType; children: React.ReactNode }) => {
  const [color900] = useToken('colors', ['brand.900']);
  return (
    <Button primary={type === ButtonType.PRIMARY} color={color900}>
      {children}
    </Button>
  );
};

export default StyledButton;
