import Login from 'pages/Login';
import { ROUTES } from 'constants/routes';
import React, { ReactNode } from 'react';

const Homepage = React.lazy(() => import('pages/Homepage'));

interface Route {
  path: string;
  element: ReactNode;
}

const routes: Array<Route> = [
  { path: ROUTES.HOMEPAGE, element: <Homepage /> },
  {
    path: ROUTES.LOGIN,
    element: <Login />
  }
];
export default routes;
